import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { BsStars } from "react-icons/bs"
import moment from "moment"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import sanityClient from "@sanity/client"

export const query = graphql`
  query BrainstormTemplateQuery($id: String!) {
    page: sanityBrainstorm(id: { eq: $id }) {
      _id
      _type
      slug {
        current
      }
      status
      timestamp
      description
      title
    }
  }
`

const IdeaEngine = props => {
  const { data } = props
  const page = data && data.page

  const client = sanityClient({
    projectId: "rbhjt84j",
    dataset: "production",
    apiVersion: "2023-01-12",
    token: process.env.GATSBY_SANITY_BRAINSTORM_KEY,
    ignoreBrowserTokenWarning: true,
  })

  const blobArray = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]

  const [stormIdeas, setStormIdeas] = useState([])
  const [name, setName] = useState("")
  const [comment, setComment] = useState("")
  const [submitted, setSubmitted] = useState(false)

  function resetForm() {
    setSubmitted(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      const documentId = page._id
      const document = await client.getDocument(documentId)
      setStormIdeas(document.stormIdeas)
    }
    fetchData()
  }, [])

  function makeid(length) {
    var result = ""
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  async function handleSubmit(event) {
    event.preventDefault()

    // Fetch the specific document you want to update
    const documentId = page._id
    const document = await client.getDocument(documentId)

    // Create the new idea object
    const newIdea = {
      _key: makeid(5),
      _type: "idea",
      name: name,
      comment: comment,
      status: false,
      timestamp: new moment().format("YYYY-MM-DD"),
    }

    // Update the ideas array on the document with the new idea
    document.stormIdeas = document.stormIdeas
      ? [...document.stormIdeas, newIdea]
      : [newIdea]

    // Commit the updated document to the Sanity database
    await client.patch(documentId).set(document).commit()

    setName("")
    setComment("")
    setSubmitted(true)
  }

  const trueIdeas = stormIdeas?.filter(idea => idea.status === true)

  return (
    <div className="bg-white dark:bg-zinc-800 text-zinc-800 dark:text-zinc-200">
      <div className=" text-[0.625rem] bg-idea7 px-2 py-1 text-zinc-100">
        <AniLink
          className="text-zinc-200"
          to="/idea-engine"
          paintDrip
          duration={0.5}
          hex={"#000cc5"}
        >
          Idea Engine{" "}
        </AniLink>
        / {page.title}
      </div>
      <Seo
        title={page.title + " - Budmen Idea Engine"}
        description={page.description}
        defer={false}
        image="https://cdn.sanity.io/images/rbhjt84j/production/3d78eca2bdbf8d20a9931a1d47ca0fb7632eeff7-1200x630.png"
        twitterImage="https://cdn.sanity.io/images/rbhjt84j/production/3d78eca2bdbf8d20a9931a1d47ca0fb7632eeff7-1200x630.png"
      />
      <div className=" container mx-auto p-4 mt-8">
        <div className="flex md:flex-row flex-col align-middle gap-3 items-center">
          <div className="flex  text-left">
            <p className="text-[0.625rem] text-zinc-600 uppercase dark:text-zinc-300 tracking-widest md:mb-0">
              BRAINSTORMING BOARD #{moment(page.timestamp).format("MM.DD.YY")}
            </p>
          </div>
        </div>

        <section className="md:flex mb-12 shadow rounded">
          <div
            style={{
              backgroundImage:
                "url(https://cdn.sanity.io/images/rbhjt84j/production/f7f27c8cfdaa48f1c89e56002db748099f4556d1-904x905.png)",
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
            className="flex md:w-1/2   border border-zinc-400 dark:border-zinc-700 rounded-tl md:rounded-tr-none rounded-tr md:rounded-bl rounded-bl-none "
          >
            <div className="bg-white/70 dark:bg-black/50 flex flex-col rounded-tl md:rounded-tr-none rounded-tr md:rounded-bl rounded-bl-none p-4">
              <div className=" border-zinc-400 dark:border-zinc-700   border-b  pb-4">
                <h5 className="text-[0.625rem] tracking-widest mb-0">TOPIC:</h5>
                <h1 className="font-bold text-lg uppercase tracking-widest">
                  {page.title}
                </h1>
              </div>
              <div className=" border-zinc-400 dark:border-zinc-700 border-b py-4 mb-4">
                <h1 className=" ">{page.description}</h1>
              </div>
              <div className="flex items-center justify-between gap-4 w-full">
                <div className="w-1/2  border-zinc-400 dark:border-zinc-700 border-r">
                  <h5 className="text-[0.625rem] tracking-widest mb-0 ">
                    BRAINSTORM DATE
                  </h5>
                  <h1 className="font-bold uppercase text-base tracking-widest">
                    {moment(page.timestamp).format("MMM DD, YYYY")}
                  </h1>
                </div>
                <div className="w-1/2">
                  <h5 className="text-[0.625rem] tracking-widest mb-0 ">
                    NO. OF IDEAS:
                  </h5>
                  <h1 className="font-bold text-base tracking-widest">
                    {trueIdeas ? trueIdeas.length : "0"}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="block md:w-1/2 bg-idea1 md:rounded-tr rounded-tr-none md:rounded-bl-none rounded-bl rounded-br">
            <form
              onSubmit={handleSubmit}
              className="block md:rounded-tr bg-black/10 rounded-tr-none md:rounded-bl-none rounded-bl rounded-br  p-4"
            >
              <h1 className="font-bold text-sm tracking-widest text-white mb-4">
                IDEA INPUT
              </h1>
              <label className="text-white">
                <input
                  type="text"
                  value={name}
                  placeholder="Enter your name"
                  onChange={event => setName(event.target.value)}
                  required
                  className="shadow appearance-none border  border-zinc-400 dark:border-zinc-700  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </label>
              <br />
              <label>
                <textarea
                  value={comment}
                  placeholder="Enter your idea..."
                  type="textarea"
                  onChange={event => setComment(event.target.value)}
                  required
                  className="shadow appearance-none border mt-4 border-zinc-400 dark:border-zinc-700  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </label>
              <br />
              <div className="flex gap-3 items-center">
                <button
                  type="submit"
                  className="inline-flex mt-4 items-center gap-2 text-sm disabled:opacity-90 disabled:hover:opacity-100 disabled:cursor-not-allowed flex-shrink-0 text-white mb-4  border-0 py-2 px-4 focus:outline-none bg-zinc-800 hover:bg-zinc-400 rounded font-bold tracking-widest uppercase"
                  disabled={submitted}
                >
                  {submitted ? "IDEA Sent" : "Submit Idea"} <BsStars />
                </button>
                {submitted && (
                  <p className="text-xs text-white bg-black/30 p-1 rounded">
                    Your idea is being processed,{" "}
                    <button
                      className="underline text-bold mt-0"
                      onClick={resetForm}
                    >
                      submit another?
                    </button>
                  </p>
                )}
              </div>
            </form>
          </div>
        </section>

        <h1 className="font-bold text-sm tracking-widest mb-4">
          ENGINE OUTPUT:
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-4">
          {stormIdeas ? (
            stormIdeas.map((comment, index) => {
              const randomIndex = Math.floor(Math.random() * blobArray.length)
              const selectedPicture = blobArray[randomIndex]
              if (comment.status !== false) {
                return (
                  <div
                    key={index}
                    className={`bg-white dark:bg-zinc-900 border-zinc-400 dark:border-zinc-700 rounded dark:text-white text-zinc-600 border shadow`}
                  >
                    <div className={` rounded-t bg-idea${selectedPicture}`}>
                      <div className="text-[0.625rem] p-2 flex rounded-t font-bold justify-between text-white bg-black/20">
                        <p className="tracking-widest">IDEA #{index + 1}</p>
                        <p className="uppercase tracking-widest">
                          By: {comment.name}
                        </p>
                      </div>
                    </div>
                    <p className="text-base normal-case  rounded-b p-2">
                      {comment.comment}
                    </p>
                  </div>
                )
              }
              return null // or return false, or return "", or return []
            })
          ) : (
            <div
              className={`bg-white dark:bg-zinc-900 border-zinc-400 dark:border-zinc-700 rounded dark:text-white text-zinc-600 border shadow`}
            >
              <div className={` rounded-t bg-idea${blobArray[0]}`}>
                <div className="text-[0.625rem] p-2 flex rounded-t font-bold justify-between text-white bg-black/20">
                  <p className="tracking-widest">IDEA #0</p>
                  <p className="uppercase tracking-widest">By: You?</p>
                </div>
              </div>
              <p className="text-base normal-case  rounded-b p-2">
                No ideas have been submitted to this brainstorm yet... 🤔💭
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default IdeaEngine
